export const useStartView = () =>
  useState<object>("startview", () => {
    return {};
  });

export const useOriginalView = () =>
  useState<object>("originalview", () => {
    return {};
  });

export const useAllowCookies = () =>
  useState<boolean>("allowcookies", () => {
    return false;
  });

export const useMap = () =>
  useState<boolean>("map", () => {
    return false;
  });

export const useModal = () =>
  useState<boolean>("modal", () => {
    return false;
  });

export const useModalId = () =>
  useState<object | null>("modalId", () => {
    return {};
  });

export const usePrevious = () =>
  useState<string>("previousUrl", () => {
    return "";
  });

export const useLoadModel = () =>
  useState<boolean>("loadModel", () => {
    return false;
  });

// Build Your Own
export const useFeature = () => useState<string | null>("feature", () => null);
export const useAnimation = () =>
  useState<string | null>("animation", () => null);
export const useReverseOptionAnimation = () =>
  useState<string | null>("reverseoptionanimation", () => null);
export const useProduct = () => useState<string>("product", () => '');
export const useProductNavi = () =>
  useState<boolean>("productnavi", () => false);

export const useLoading = () => useState<Boolean>("loading", () => true);
export const usePlaying = () => useState<Boolean>("playing", () => false);
export const useSide = () => useState<Boolean>("side", () => true);
export const useInfo = () => useState<boolean>("info", () => false);

export const usePlayedAnimations = () =>
  useState<[]>("playedAnimations ", () => []);

export const useGroupedOptions = () => useState<[]>("groupedOptions", () => []);

